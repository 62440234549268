import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { NavDropdown } from "react-bootstrap";

interface Props {
    showDot: boolean;
    onShowMyProfileModal: (show: boolean) => void;
}

const MyProfileWrapper = (props: Props) => {

    
    const handleMyProfileClick = () => {
        props.onShowMyProfileModal(true);
    }

    return (
        <>
            <NavDropdown.Item
                onClick={() => handleMyProfileClick()}>
                <span>Min profil{props.showDot && (<IcebergIcon icon="dot" color='orange' />)}</span>
            </NavDropdown.Item>
            
        </>
    );
};
export default MyProfileWrapper;