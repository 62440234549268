import { useEffect, useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import cx from 'classnames';


interface Props {
	onSelectionChanged: (funksjoner: string[]) => void;
	selected?: string[];
	disabled?: boolean;
	mode?: 'multiSelect' | 'simpleSelect';
}

// Statisk liste med jobbfunksjoner
export const JobFunctions = [
	'Daglig leder',
	'Butikkledelse',
	'Butikkmedarbeider',
	'Selger',
	'KAM',
	'Salgsledelse',
	'Kategori- og produktledelse',
	'Marked og kommunikasjon',
	'Innkjøp/Kategori',
	'Logistikk og vareflyt',
	'Transport',
	'Administrasjon og ledelse',
	'IT/utvikling',
	'Drift',
	'Økonomi/HR',
	'Masterdata'
];

interface JobFunctionItem {
	label: string;
	id: number;
	value: string;
	checked: boolean;
}

const JobbfunksjonPicker = (props: Props) => {
	const [jobFunctionList, setjobFunctionList] = useState<JobFunctionItem[]>([]);

	// Konverterer den statiske listen til et format som DropdownTreeSelect forstår
	const buildJobFunctionTree = () => {
		return JobFunctions.map((funksjon, index) => ({
			label: funksjon,
			id: index + 1, // Start id fra 1
			value: funksjon,
			checked: props.selected?.includes(funksjon) ? true : false, // Sjekker om noden er valgt

		}));
	};

	useEffect(() => {
		setjobFunctionList(buildJobFunctionTree());
		console.log('JobbfunksjonPicker: ', props.selected);
	}, [props.selected]);

	// Håndterer endringer når bruker velger jobbfunksjoner
	const handleOnChange = (currentNode: any, selectedNodes: any[]) => {
		const jobFunctionItems: JobFunctionItem[] = selectedNodes.map((node) => ({
			label: node.label,
			id: node._id,
			value: node.value,
			checked: node.checked,
		}));
		props.onSelectionChanged(jobFunctionItems.map((funksjon) => funksjon.value));
	};

	return (
		<>
			<DropdownTreeSelect
				className={cx('reactDropdownMultiPicker')}
				mode={props.mode || 'multiSelect'}
				texts={{
					placeholder: 'Velg jobbfunksjon',
					noMatches: 'Ingen treff',
				}}
				data={jobFunctionList || []}
				keepTreeOnSearch
				keepChildrenOnSearch
				showPartiallySelected
				onChange={handleOnChange}
				disabled={props.disabled}
			/>
		</>
	);
};

export default JobbfunksjonPicker;