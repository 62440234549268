import { IconButton, IcebergTextTooltip } from "@tradesolution/iceberg-ui-react";
import { useEffect, useState } from "react";
import { Alert, Form, InputGroup, Modal } from "react-bootstrap";
import ProfileApi, { IProfile, UpdateMyProfileCommand } from "./ProfileApi";
import JobbfunksjonPicker from "./JobbfunksjonPicker";
import { SubmitHandler, useForm } from "react-hook-form";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import nb from 'react-phone-number-input/locale/nb.json'
import { E164Number } from 'libphonenumber-js/types.cjs';

interface Props {
	show: boolean;
	onClose: () => void;
	myProfile: IProfile | undefined;
}

const MyProfile = (props: Props) => {
	const { register, handleSubmit, formState: { errors }, reset, setValue, setError} = useForm<UpdateMyProfileCommand>()	
	const [mobile, setMobile] = useState<string>();
	const [selectedJobFunctions, setSelectedJobFunctions] = useState<string[]>([]);

	const loadProfile = async () => {		
		if(!props.myProfile) {
			return;
		}
		reset({
			givenName: props.myProfile.givenName,
			surname: props.myProfile.surname,
			mobile: props.myProfile.mobile,
			otherEmail: props.myProfile.otherEmail,
			jobTitle: props.myProfile.jobTitle,
			jobFunctions: props.myProfile.jobFunctions || [],
		  });
		setSelectedJobFunctions(props.myProfile.jobFunctions || []);
		setMobile(props.myProfile.mobile);
	};

	useEffect(() => {		
		loadProfile();
	}, [props.myProfile]);

	const onSubmit: SubmitHandler<UpdateMyProfileCommand> = async data => {
		data.jobFunctions = selectedJobFunctions;
		console.log(data);
		await ProfileApi.put(data);
		props.onClose();
	  };

	const handleClose = async () => {
		props.onClose();
	};

	const handleJobFunctionsChange = (funksjoner: string[]) => {
		setSelectedJobFunctions(funksjoner);
	};

	const handleMobileChange = (num?: E164Number) => {
		if(!num) {
			return;
		}
		if (num.length > 20) {
			setError('mobile', {
			  type: 'manual',
			  message: 'Telefonnummer kan max ha 20 tegn',
			});
		  } else {
			setValue('mobile', num, { shouldValidate: true });
			setMobile(num);
		  }
	  };

	return (
		<Modal size="lg" show={props.show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					Din profil
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>				
				{props.myProfile?.needsConfirmation && (
					<Alert variant="warning">
						Har noe endret seg? Trykk "Lagre" når alt ser bra ut
					</Alert>					
				)}
				{props.myProfile?.dataControlledByYourCompany && (
						<Alert variant="warning">
							Du kan bare endre "Jobb funksjoner" siden resten av dine data styres av din organisasjon
						</Alert>
					)}			
				<Form>
				<Form.Group className="mb-3">
						<Form.Label>E-post - Ta kontakt med <a href="https://tradesolution.no/support/">support</a> hvis du trenger å endre e-posten din</Form.Label>
						<InputGroup>
							<Form.Control
							 disabled
							 type="text"
							 value={props.myProfile?.email} />							 
						</InputGroup>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Fornavn</Form.Label>
						<InputGroup>
							<Form.Control
							 disabled={props.myProfile?.dataControlledByYourCompany}
							 type="text" 
							 isInvalid={!!errors.givenName}
							 {...register("givenName", { required: "Fornavn er påkrevd", maxLength: { value: 100, message:"Fornavn kan max ha 100 tegn"}})} />
							 <Form.Control.Feedback type="invalid">
							 	{errors.givenName && <span>{errors.givenName.message}</span>}
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Etternavn</Form.Label>
						<InputGroup>
							<Form.Control
							 disabled={props.myProfile?.dataControlledByYourCompany}
							 type="text"
							 isInvalid={!!errors.surname}
							 {...register("surname", { required: "Etternavn er påkrevd", maxLength: { value: 100, message:"Etternavn kan max ha 100 tegn"}})}  />
							 <Form.Control.Feedback type="invalid">
							 	{errors.surname && <span>{errors.surname.message}</span>}
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>
					<Form.Group className="mb-3">
					<IcebergTextTooltip
									icon="circle-question"
									placement="top"
									tooltip="Jobbfunksjoner brukes av Tradesolution for å kunne gi deg målrettet kommunikasjon"
								>
									<Form.Label>Jobbfunksjoner (sterkt ønskelig) {selectedJobFunctions.length === 0 || selectedJobFunctions[0] === '' ? ' - Vennligst velg jobb funksjoner som best beskriver hva du gjør på jobb' : ''}</Form.Label>
								</IcebergTextTooltip>
						<InputGroup>
							<JobbfunksjonPicker
								onSelectionChanged={handleJobFunctionsChange}
								selected={selectedJobFunctions}
							/>
						</InputGroup>
					</Form.Group>					
					<Form.Group className="mb-3">
						<Form.Label>Telefon (valgfritt)</Form.Label>
						<InputGroup>
							{props.myProfile?.dataControlledByYourCompany && (
								<Form.Control
									disabled
									type="text"
									value={mobile}
								  />
							)}
							{!props.myProfile?.dataControlledByYourCompany && (
								<PhoneInputWithCountrySelect								
								labels={nb}
								countryOptionsOrder={['NO', 'SE', 'DK']}
								defaultCountry='NO'
								className='form-control'
								value={mobile}							
								onChange={handleMobileChange} />
							)}											 
						</InputGroup>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Alternativ e-post (valgfritt)</Form.Label>
						<InputGroup>
							<Form.Control
							 disabled={props.myProfile?.dataControlledByYourCompany}
							 type="text"
							 isInvalid={!!errors.otherEmail}
							 {...register("otherEmail", { 
								maxLength: {value: 100, message: "E-post kan max ha 100 tegn"},
								pattern: {
									value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
									message: "Ugyldig e-postadresse"
								  }})} />
							 <Form.Control.Feedback type="invalid">
							 	{errors.otherEmail && <span>{errors.otherEmail.message}</span>}
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>
					<Form.Group className="mb-3">
						<IcebergTextTooltip
									placement="top"
									icon="circle-question"
									tooltip="Stillingstittel er den tittelen du har internt i din organisasjon"
								>
									<Form.Label>Stillingstittel (valgfritt)</Form.Label>
						</IcebergTextTooltip>
						<InputGroup>
							<Form.Control
							 disabled={props.myProfile?.dataControlledByYourCompany}
							 type="text"
							 isInvalid={!!errors.jobTitle}
							 {...register("jobTitle", { maxLength: {value: 100, message: "Stillingstittel kan max ha 100 tegn"}})} />
							 <Form.Control.Feedback type="invalid">
							 	{errors.jobTitle && <span>{errors.jobTitle.message}</span>}
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>					
				</Form>						
			</Modal.Body>
			<Modal.Footer>
				<>
					<IconButton variant="outline-primary" icon="close" className="col" onClick={handleClose}>
						Avbryt
					</IconButton>
					<IconButton variant="primary" icon="disk" className="col" onClick={handleSubmit(onSubmit)}>
						Lagre
					</IconButton>
				</>
			</Modal.Footer>
		</Modal >
	);
};

export default MyProfile;

function setValue(arg0: string, num: E164Number, arg2: { shouldValidate: boolean; }) {
	throw new Error("Function not implemented.");
}
