import axios from 'axios';

export interface IProfile {
  email: string;
  givenName: string;
  surname: string;
  otherEmail?: string;
  mobile?: string;
  jobTitle?: string;
  jobFunctions?: string[];
  dataControlledByYourCompany: boolean;
  confirmedAt?: Date;
  needsConfirmation: boolean;  
}

export interface UpdateMyProfileCommand {  
  givenName: string;
  surname: string;
  otherEmail?: string;
  mobile?: string;
  jobTitle?: string;
  jobFunctions?: string[];
}

const profileBaseUrl = () => {	
	return `https://adminportalenapi.tradesolution.no/api`;
  //return `https://localhost:7216/api`;
};

const ProfileApi = {
	getMyProfile: async () : Promise<IProfile> => {
    let url = `${profileBaseUrl()}/Profiles/MyProfile`;
    return (await axios.get(url)).data;
    },
  put: async (profile: UpdateMyProfileCommand) : Promise<IProfile> => {
    let url = `${profileBaseUrl()}/Profiles`;
    return (await axios.put(url, profile)).data;
    },  
};

export default ProfileApi;